<div class="group relative">
	<ng-content></ng-content>
	<div
		class="montserrat xs:p-m-16 sm:p-m-16 md:p-m-18 lg:p-m-16 xl:p-m-18 absolute left-1/2 top-full mt-2 flex w-max -translate-x-[85%] translate-y-6 flex-row rounded-2xl bg-VerdeOscuro px-3 py-2 text-Blanco shadow-lg">
		<img *ngIf="img" class="nav-pin me-1" [src]="img" alt="map pin" />
		<div>
			{{ text }}
		</div>
	</div>
</div>
