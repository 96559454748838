import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrl: './tooltip.component.sass',
})
export class TooltipComponent {
	@Input() text: string = '';
	@Input() img!: string;
}
