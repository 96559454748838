import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST, SsrCookieService } from 'ngx-cookie-service-ssr';
import { BehaviorSubject, first, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CookieService {
	private _thirdPartyCookiesConsent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
	private _mandatoryCookiesConsent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	private readonly MANDATORY_COOKIES: string[] = ['cookies-consent', 'pll_language'];

	constructor(
		private ssrCookieService: SsrCookieService,
		@Inject(PLATFORM_ID) private platformId: object,
		@Optional() @Inject(REQUEST) private req: Request
	) {
		if (isPlatformBrowser(this.platformId)) {
			// update cookie state on browser side
			this._mandatoryCookiesConsent.next(this.ssrCookieService.get('cookies-consent') === 'true');
			this._thirdPartyCookiesConsent.next(this.ssrCookieService.get('_ga') != '');
		}
	}

	public getMandatoryCookiesConsent(): Observable<boolean> {
		/**
		 * Returns mandatory cookies consent
		 * @returns {boolean}
		 */
		return this._mandatoryCookiesConsent.asObservable();
	}

	public getThirdPartyCookiesConsent(): Observable<boolean> {
		/**
		 * Returns third party cookies consent
		 * @returns {boolean}
		 */
		return this._thirdPartyCookiesConsent.asObservable();
	}

	public acceptMandatoryCookies(): void {
		/**
		 * This method sets only de mandatory cookies
		 */
		this._mandatoryCookiesConsent.next(true);
		this.deleteThirdPartyCookies();
		this.ssrCookieService.set('cookies-consent', 'true', { expires: 365 });
	}

	public acceptMandatoryAndThirdPartyCookies(): void {
		/**
		 * This method sets the mandatory and third party cookies
		 */
		this._thirdPartyCookiesConsent.next(true);
		this._mandatoryCookiesConsent.next(true);
		this.ssrCookieService.set('cookies-consent', 'true', { expires: 365 });
	}

	public deleteAllCookies(): void {
		/**
		 * Deletes all cookies.
		 */
		this._mandatoryCookiesConsent.next(false);
		this._thirdPartyCookiesConsent.next(false);
		this.ssrCookieService.deleteAll();

		// las cookies que se usen en el documento de otros dominios no se pueden eliminar con el ssrCookieService
		// asi que ejecutamos esto para eliminarlas. Fuente:
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const equals = cookies[i].indexOf('=');
			const name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
			this.ssrCookieService.delete(name.trim(), '/', '.thewiseseeker.com');
			this.ssrCookieService.delete(name.trim(), '/', '.tws-labs.com');
		}
		// Este código era para meter las cookies de Google Analytics, lo cual requería hacer el reload
		// window.location.reload();
	}

	public deleteThirdPartyCookies(): void {
		/**
		 * Deletes third party cookies.
		 */
		this.getThirdPartyCookiesConsent()
			.pipe(first())
			.subscribe((val) => {
				if (val) this._thirdPartyCookiesConsent.next(false);
			});
		const cookiesMap = this.ssrCookieService.getAll();
		let cookiesNames = Object.keys(cookiesMap);
		cookiesNames = cookiesNames.filter((cookieName) => !this.MANDATORY_COOKIES.includes(cookieName));
		cookiesNames.forEach((cookieName) => {
			this.ssrCookieService.delete(cookieName.trim(), '/', '.thewiseseeker.com');
			this.ssrCookieService.delete(cookieName.trim(), '/', '.tws-labs.com');
		});
	}

	public setLanguageCookie(language: string): void {
		/**
		 * @param language the language to set cookie
		 * Set pll_language to the language given if cookies have been accepted
		 */
		if (this._mandatoryCookiesConsent.value) {
			this.ssrCookieService.set('pll_language', language, {
				expires: 365,
			});
		}
	}

	public getLanguage(): string {
		/**
		 * Returns the value of 'pll_language' cookie
		 * @returns {string} language
		 */
		return this.ssrCookieService.check('pll_language') ? this.ssrCookieService.get('pll_language') : '';
	}
}
